<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 201px)">
        <div class="row mx-0 justify-center">
            <div class="col-10">
                <vueper-slides class="no-shadow h-vueper2" :arrows-outside="false" bullets-outside>
                    <template #arrow-left>
                        <div class="d-middle-center bg-white rounded-circle shadow" style="width:24px;height:24px;">
                            <i class="icon-angle-left text-general" />
                        </div>
                    </template>
                    <template #arrow-right>
                        <div class="d-middle-center bg-white rounded-circle shadow" style="width:24px;height:24px;">
                            <i class="icon-angle-right text-general" />
                        </div>
                    </template>
                    <vueper-slide
                    v-for="(img, i) in producto.imagenes"
                    :key="i"
                    >
                        <template #content>
                            <div class="row mx-0 mt-1 justify-center">
                                <img :src="img.imagen" height="240" width="240" />
                            </div>
                        </template>
                    </vueper-slide>
                </vueper-slides>
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div v-if="producto.promocion" class="mn-pill text-white mr-2 bg-orange mt-3 py-3">
                {{ producto.data_promocion.texto }}
            </div>
            <div v-show="tipoImpuesto === 2 && producto.porcentaje_impuesto" class="mn-pill bg-general3 text-white mt-3 py-3">
                IVA incluido
            </div>
            <div v-show="tipoImpuesto === 1 && producto.porcentaje_impuesto" class="mn-pill bg-general3 text-white mt-3 py-3">
                + IVA
            </div>
        </div>
        <div class="mx-4 border br-8 p-2">
            <div class="row mx-0">
                <div class="mn-pill bg-general text-white f-500">
                    {{ agregarSeparadoresNumero(producto.cant_stock) }} {{ producto.unidad }}
                </div>
                <div class="mn-pill bg-gris2 px-2 ml-2 text-white">
                    <i class="icon-rotation mr-1" /> <span class="f-600 mr-1">{{ agregarSeparadoresNumero(producto.rotacion,1) }}</span>  Rotacion
                </div>
            </div>
            <div class="row mx-0 my-2">
                <div class="col px-0">
                    <div class="mn-pill bg-green text-white justify-content-start" style="height:27px;">
                        <i class="icon-cart-outline f-15" />
                        <span class="f-500 f-15 mx-1">{{ agregarSeparadoresNumero(producto.en_carrito_cliente) }}</span>
                        <span class="f-15">en carrito de cliente</span>
                    </div>
                </div>
            </div>
            <div v-show="producto.venta_sin_stock" class="row mx-0 text-general">
                <i class="icon-cash-lock-open" />
                <span class="ml-2">
                    Venta sin stock
                </span>
            </div>
        </div>
        <div class="row mx-4 mt-3">
            <div v-if="producto.promocion" class="col-auto px-0 f-600 text-general f-25">
                {{ funValor(producto.data_promocion.promo_valor) }}
            </div>
            <div v-else class="col-auto px-0 f-600 text-general f-25">
                {{ funValor(producto.valor) }}
            </div>
            <div v-show="producto.promocion" class="col-auto px-0 f-600 text-gris2 f-25 ml-3">
                <strike class="f-12">
                    {{ funValor(producto.valor) }}
                </strike>
            </div>
        </div>
        <div class="row mx-4">
            <div class="col-12 px-0 text-general f-20 f-600">
                {{ producto.nombre }}
            </div>
            <p class="f-15 text-gris2">
                {{ producto.presentacion }}
            </p>
        </div>
        <!-- Añadir al carro -->
        <div class="row mx-4 mt-2">
            <div v-if="producto.en_carrito === null" class="col-12 px-0">
                <div class="btn-general f-12" style="height:40px;" @click="agregarAlCarrito()">
                    Añadir al carrito
                </div>
            </div>
            <div v-else class="col-12 px-0 d-middle">
                <div class="col-auto px-0">
                    <p class="text-general lh-18 f-14 f-600 mt-1">
                        {{ funValor(totalAPagar) }}
                    </p>
                    <p class="text-gris2 f-12">
                        Total a pagar
                    </p>
                </div>
                <div class="btn-remove ml-auto text-red mx-2 cr-pointer" @click="cambiarCantidad(0)">
                    <i class="icon-trash-empty f-16" />
                </div>
                <el-input-number
                v-model="producto.en_carrito"
                :min="parseFloat(producto.compra_minima)"
                :step="parseFloat(producto.cantidad_minima)"
                :step-strictly="entero"
                :precision="precision"
                size="medium"
                class="card-producto__contador"
                @change="cambiarCantidad"
                />
            </div>
        </div>
        <!-- Añadir al carro -->
        <div class="row mx-0 py-3" />
        <div v-show="tienda.editar_precios_inventario">
            <div class="row mx-4">
                <div class="col-12 px-0 text-general f-20 f-500">
                    Editar precio del producto
                </div>
                <div class="col-12 px-0 text-general f-25">
                    <span v-if="producto.promocion" class="f-600">{{ funValor(producto.data_promocion.promo_valor) }}</span>
                    <span v-else class="f-600"> {{ funValor(producto.valor) }}</span>
                    <small class="f-17">
                        Leeche
                    </small>
                </div>
            </div>
            <div v-if="tienda.ganancia_predeterminada == 0" class="row mx-4 align-items-center br-12 p-2 bg-gris2 text-white">
                <div class="col-auto f-30 text-white f-500">
                    0%
                </div>
                <div class="col px-0">
                    Ganancia sobre <br /> el producto
                </div>
                <i class="icon-pencil-outline f-20" @click="editarValor" />
            </div>
            <div v-if="tienda.ganancia_predeterminada != 0 && (producto.valor_tienda == null || producto.valor_tienda == 0)" class="row mx-4 align-items-center br-12 p-2 bg-green mt-2 text-white">
                <div class="col-auto f-30 text-white f-500">
                    {{ tienda.ganancia_predeterminada }}%
                </div>
                <div class="col px-0">
                    Ganancia sobre <br /> el producto
                </div>
                <i class="icon-pencil-outline f-20" @click="editarValor" />
            </div>
            <div v-if="producto.ganancia > 0 && producto.valor_tienda > 0" class="row mx-4 align-items-center br-12 p-2 bg-green text-white mt-3">
                <div class="col-auto f-30 text-white f-500">
                    {{ agregarSeparadoresNumero(producto.ganancia,1) }}%
                </div>
                <div class="col px-0">
                    Ganancia sobre <br /> el producto
                </div>
                <i class="icon-pencil-outline f-20" @click="editarValor" />
                <div class="col-12 border" />
                <div class="col-auto f-30 text-white f-500">
                    {{ funValor(producto.valor_tienda) }}
                </div>
                <small class="f-15">
                    Nuevo valor
                </small>
            </div>
            <div v-if="producto.ganancia <= 0 && producto.valor_tienda > 0" class="row mx-4 align-items-center br-12 p-2 bg-red text-white mt-3">
                <div class="col-auto f-30 text-white f-500">
                    {{ agregarSeparadoresNumero(producto.ganancia,1) }}%
                </div>
                <div class="col px-0">
                    Ganancia sobre <br /> el producto
                </div>
                <i class="icon-pencil-outline f-20" @click="editarValor" />
                <div class="col-12 border" />
                <div class="col-auto f-30 text-white f-500">
                    {{ funValor(producto.valor_tienda) }}
                </div>
                <small class="f-15">
                    Nuevo valor
                </small>
            </div>
            <div v-show="producto.valor_tienda > 0" class="row mx-4 align-items-center my-2">
                <i class="icon-alert-circled f-20 text-alert" />
                <div class="col text-gris2 px-2">
                    El precio de este producto ha sido modificado de manera individual
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-2" />
        <div class="row mx-4 mb-3">
            <p class="col-12 px-0 text-gris2 ">
                {{ producto.descripcion }}
            </p>
            <p class="col-12 px-0 f-15 text-gris2 mt-2">
                <span class="f-600">Unidad de medida:</span>
                {{ producto.unidad }}
            </p>
            <p class="col-12 px-0 f-15 text-gris2 mt-2">
                <span class="f-600">Venta mínima:</span>
                {{ producto.compra_minima }} - {{ producto.unidad }}
            </p>
        </div>
        <!-- Partials -->
        <modal ref="modalEditarValor" titulo="Editar el valor del producto" no-aceptar adicional="Actualizar" @adicional="setValorProductoTienda">
            <div class="row mx-0 justify-center">
                <div class="col-8">
                    <p class="text-general f-12 pl-3">
                        Nuevo valor de venta
                    </p>
                    <!-- <el-input v-model="nuevo_valor" placeholder="0.000" class="w-100" size="medium" /> -->
                    <money v-model="newValor" class="input-money" v-bind="money" />
                </div>
                <div class="col-8">
                    <div class="row mx-0 justify-content-end pt-3">
                        <div class="mn-pill bg-general3 text-white px-2 cr-pointer" @click="setValorProductoTienda(true)">
                            Restablecer
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import {Money} from 'v-money'
import {mapGetters} from 'vuex'
import Productos from "~/services/productos/productosTendero"
export default {
    components:{
        Money,
    },
    data(){
        return {
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            newValor: 0,
            producto: {}
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            tiendaStore: 'tiendas/info_cabecera/tienda'
        }),
        tienda(){
            let rol = this.$usuario.rol
            if(rol === 1 || rol === 3){
                return this.tiendaStore
            }
            return this.$tienda
        },
        tipoImpuesto(){
            return this.tienda.tipo_impuesto
        },
        impuesto(){
            const i = this.producto.porcentaje_impuesto
            return i ? i : 0
        },
        entero(){
            if(this.producto.cantidad_tipo === 1){
                return true
            }else{
                return false
            }
        },
        precision(){
            if(this.producto.cantidad_tipo === 1){
                return 0
            }else{
                return 2
            }
        },
        totalAPagar(){
            let valor = 0
            if(this.producto.promocion){
                valor = this.producto.data_promocion.promo_valor
            }else{
                valor = this.producto.valor
            }

            return valor * this.producto.en_carrito
        }
    },
    watch:{
        id_producto(val){
            if(val){
                this.detalleProducto()
            }
        }
    },
    methods: {
        async detalleProducto(){
            try {
                const params = {id_producto:this.id_producto, id_tienda: this.tienda.id}
                const {data} = await Productos.detalleProducto(params)

                this.producto = data.producto
                this.setValor()

            } catch (error){
                this.error_catch(error)
            }
        },
        setValor(){
            if(this.producto.valor_tienda === null || this.producto.valor_tienda == 0){
                this.newValor = 0
            }else{
                this.newValor = parseFloat(this.producto.valor_tienda)
            }
        },
        funValor(value){
            let valor = 0
            if(this.tipoImpuesto === 0){
                valor = value
            }
            if(this.tipoImpuesto === 1){
                valor = value / (1 + this.impuesto / 100)
            }
            if(this.tipoImpuesto === 2 || this.tipoImpuesto === 3){
                valor = value
            }

            let rol = this.$usuario.rol
            if(rol === 1 || rol === 3){
                return this.convertMoney(valor,this.tienda.idm_moneda)
            }
            return this.convertMoneyTendero(valor,this.tienda.idm_moneda)
        },
        async agregarAlCarrito(){
            try {

                const form = {
                    id_producto:this.producto.id,
                    id_tienda: this.tienda.id
                }
                const {data} = await Productos.agregarAlCarrito(form)
                this.producto.en_carrito = data.producto.cantidad
            } catch (error){
                this.error_catch(error)
            }
        },
        async cambiarCantidad(value){
            try {
                const update = async() => {
                    const form = {
                        id_producto:this.producto.id,
                        id_tienda: this.tienda.id,
                        cantidad: value
                    }
                    const {data} = await Productos.updateCantidadProducto(form)
                    if(value === 0){
                        this.producto.en_carrito = null
                    }
                }
                await this.delay(update)

            } catch (error){
                this.error_catch(error)
            }
        },
        async setValorProductoTienda(restablecer = false){
            try {

                if(restablecer){
                    this.newValor = 0
                }

                const form = {
                    id_tienda: this.tienda.id,
                    id_producto: this.id_producto,
                    valor: this.newValor
                }

                const {data} = await Productos.setValorProductoTienda(form)
                this.notificacion('','Precio Actualizado correctamente','success')
                this.$refs.modalEditarValor.toggle();
                this.detalleProducto()

            } catch (error){
                this.error_catch(error)
            }
        },
        editarValor(){
            this.$refs.modalEditarValor.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-remove{
    width: 35px;
    height: 35px;
    border: 1px solid #FF1720 !important;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF1720 !important;
}
.btn-minus{
    width: 44px;
    height: 44px;
    border: 1px solid var(--color-general) !important;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-general) !important;
}
.btn-plus{
    width: 44px;
    height: 44px;
    border: 1px solid var(--color-general) !important;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-general) !important;
    color: #FFFFFF !important;
}
</style>
